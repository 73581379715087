<template>
	<div class="container">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>管理后台</el-breadcrumb-item>
			<el-breadcrumb-item>首页</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-row>
				<el-col :span="24">
					<div @click="outChart()" class="welcome-date">
						<div class="welcome">{{adminName}}，欢迎您</div>
						<div class="today">今天是{{today}}，{{week}}</div>
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<div class="administrator">{{company}} | {{branch_content=='null' ? '超级管理员':branch_content}}</div>
				</el-col>
			</el-row>
		</el-card>
		<div class="overall">
			<div class="all-item">
				<div class="item-box">
					<div class="all-title">可视员工数</div>
					<div class="all-people"><span>{{allNum}}</span>人</div>
					<div class="go-check" @click="checkPersonnel">去看看</div>
				</div>
			</div>
			<div class="all-item">
				<div class="item-box">
					<div class="all-title">今日总体心情指数</div>
					<div class="index-box">
						<div class="mean-index" v-if="allEmotion!=-1">均值<span
								:style="emotionColor">{{allEmotion}}</span></div>
						<div class="mean-index" v-else>暂无数据</div>
						<img src="../assets/images/emotion1.png" alt="" class="index-pic" v-if="emotionIcn==3">
						<img src="../assets/images/emotion2.png" alt="" class="index-pic" v-if="emotionIcn==2">
						<img src="../assets/images/emotion3.png" alt="" class="index-pic" v-if="emotionIcn==1">
					</div>
					<div class="contrast" v-if="allEmotion!=-1&&allEmotionPre!=-1">同比昨日<span
							v-if="allEmotion>allEmotionPre">上升</span><span
							v-if="allEmotion<=allEmotionPre">下降</span>{{contrastEmotion}}%</div>
				</div>
			</div>
			<div class="all-item">
				<div class="item-box">
					<div class="all-title">今日总体疲劳指数</div>
					<div class="index-box">
						<div class="mean-index" v-if="allTired!=-1">均值<span :style="tiredColor">{{allTired}}</span>
						</div>
						<div class="mean-index" v-else>暂无数据</div>
						<img src="../assets/images/tired1.png" alt="" class="index-pic" v-if="tiredIcn==1">
						<img src="../assets/images/tired2.png" alt="" class="index-pic" v-if="tiredIcn==2">
						<img src="../assets/images/tired3.png" alt="" class="index-pic" v-if="tiredIcn==3">
					</div>
					<div class="contrast" v-if="allTired!=-1&&allTiredPre!=-1">同比昨日<span
							v-if="allTired>allTiredPre">上升</span><span
							v-if="allTired<=allTiredPre">下降</span>{{contrastTired}}%</div>
				</div>
			</div>
			<div class="all-item">
				<div class="item-box">
					<div class="all-title">今日总体压力指数</div>
					<div class="index-box">
						<div class="mean-index" v-if="allStress!=-1">均值<span :style="stressColor">{{allStress}}</span>
						</div>
						<div class="mean-index" v-else>暂无数据</div>
						<img src="../assets/images/stress1.png" alt="" class="index-pic" v-if="stressIcn==1">
						<img src="../assets/images/stress2.png" alt="" class="index-pic" v-if="stressIcn==2">
						<img src="../assets/images/stress3.png" alt="" class="index-pic" v-if="stressIcn==3">
					</div>
					<div class="contrast" v-if="allStress!=-1&&allStressPre!=-1">同比昨日<span
							v-if="allStress>allStressPre">上升</span><span
							v-if="allStress<=allStressPre">下降</span>{{contrastStress}}%</div>
				</div>
			</div>
		</div>
		<div class="hour-index">
			<div class="hour-item">
				<div class="hour-box">
					<div class="hour-title">心情指数-概览</div>
					<el-table :data="hourAll" style="width: 100%" ref="emotion" max-height="400">
						<el-table-column prop="nickname" label="姓名">
						</el-table-column>
						<el-table-column prop="emotion_mean" label="心情指数">
							<template slot-scope="scope">
								<div class="index-show">
									<div class="row-index">{{scope.row.emotion_mean}}</div>
									<div class="dot" :style="formatIndex(scope.row.emotion_mean,'emotion')"></div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="所在部门">
							<template slot-scope="scope">
								{{formatDepartment(scope.row)}}
							</template>
						</el-table-column>
						<el-table-column prop="job" label="职务">
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div class="hour-item">
				<div class="hour-box">
					<div class="hour-title">疲劳指数-概览</div>
					<el-table :data="hourAll" style="width: 100%" ref="tired" max-height="400">
						<el-table-column prop="nickname" label="姓名">
						</el-table-column>
						<el-table-column prop="tired_mean" label="疲劳指数">
							<template slot-scope="scope">
								<div class="index-show">
									<div class="row-index">{{scope.row.tired_mean}}</div>
									<div class="dot" :style="formatIndex(scope.row.tired_mean,'tired')"></div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="所在部门">
							<template slot-scope="scope">
								{{formatDepartment(scope.row)}}
							</template>
						</el-table-column>
						<el-table-column prop="job" label="职务">
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div class="hour-item">
				<div class="hour-box">
					<div class="hour-title">压力指数-概览</div>
					<el-table :data="hourAll" style="width: 100%" ref="stress" max-height="400">
						<el-table-column prop="nickname" label="姓名">
						</el-table-column>
						<el-table-column prop="stress_mean" label="压力指数">
							<template slot-scope="scope">
								<div class="index-show">
									<div class="row-index">{{scope.row.stress_mean}}</div>
									<div class="dot" :style="formatIndex(scope.row.stress_mean,'stress')"></div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="所在部门">
							<template slot-scope="scope">
								{{formatDepartment(scope.row)}}
							</template>
						</el-table-column>
						<el-table-column prop="job" label="职务">
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<div class="emotion-main">
			<div class="index-main">
				<div class="main-box">
					<div class="main-head">
						<div class="main-title">心情指数<span>{{today}}</span></div>
						<div class="choice-date"></div>
					</div>
					<div class="chart-main">
						<div id="emotion-chart"></div>
					</div>
				</div>
			</div>
			<div class="warning-main">
				<div class="main-box">
					<div class="main-title">心情较差人员预警</div>
					<el-table :data="emotionWarning" style="width: 100%">
						<el-table-column prop="sort" label="排名">
						</el-table-column>
						<el-table-column prop="nickname" label="姓名">
						</el-table-column>
						<el-table-column prop="emotion_mean" label="心情指数">
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<div class="emotion-main">
			<div class="index-main">
				<div class="main-box">
					<div class="main-head">
						<div class="main-title">疲劳指数<span>{{today}}</span></div>
						<div class="choice-date"></div>
					</div>
					<div class="chart-main">
						<div id="tired-chart"></div>
					</div>
				</div>
			</div>
			<div class="warning-main">
				<div class="main-box">
					<div class="main-title">疲劳人员预警</div>
					<el-table :data="tiredWarning" style="width: 100%">
						<el-table-column prop="sort" label="排名">
						</el-table-column>
						<el-table-column prop="nickname" label="姓名">
						</el-table-column>
						<el-table-column prop="tired_mean" label="疲劳指数">
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<div class="emotion-main">
			<div class="index-main">
				<div class="main-box">
					<div class="main-head">
						<div class="main-title">压力指数<span>{{today}}</span></div>
						<div class="choice-date"></div>
					</div>
					<div class="chart-main">
						<div id="stress-chart"></div>
					</div>
				</div>
			</div>
			<div class="warning-main">
				<div class="main-box">
					<div class="main-title">压力人员预警</div>
					<el-table :data="stressWarning" style="width: 100%">
						<el-table-column prop="sort" label="排名">
						</el-table-column>
						<el-table-column prop="nickname" label="姓名">
						</el-table-column>
						<el-table-column prop="stress_mean" label="压力指数">
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		indicators,
		overall,
		hour_index,
		all_hour_mean,
		hour_warning
	} from '../api/index'
	export default {
		data() {
			return {
				adminName: '',
				company: '',
				branch_content: '',
				branch_level: '',
				today: '',
				week: '',
				allNum: 0,
				allEmotion: -1,
				allStress: -1,
				allTired: -1,
				allEmotionPre: -1,
				allStressPre: -1,
				allTiredPre: -1,
				contrastEmotion: 0,
				contrastStress: 0,
				contrastTired: 0,
				emotionColor: '',
				tiredColor: '',
				stressColor: '',
				emotionIcn: 0,
				tiredIcn: 0,
				stressIcn: 0,
				hourAll: [],
				indicatorsData: [],
				emotionAllHour: [],
				stressAllHour: [],
				tiredAllHour: [],
				emotion_chart: '',
				stress_chart: '',
				tired_chart: '',
				emotionWarning: [],
				tiredWarning: [],
				stressWarning: [],
				timer: null,
				curl:''
			};
		},
		computed: {},
		methods: {
			outChart() {
				this.curl = this.emotion_chart.getDataURL({
					pixelRatio: 2,
					backgroundColor: '#fff'
				});
			},
			indicatorsAll() {
				indicators().then(res => {
					if (res.data.status) {
						this.indicatorsData = res.data.indicators
					}
				})
			},
			formatIndex(index, type) {
				if (type == 'emotion') {
					if (index >= 0 && index <= 49) {
						return {
							backgroundColor: '#FF0036'
						}
					}
					if (index >= 50 && index <= 69) {
						return {
							backgroundColor: '#01D6D6'
						}
					}
					if (index >= 70 && index <= 100) {
						return {
							backgroundColor: '#55CF04'
						}
					}
				}
				if (type == 'tired') {
					if (index >= 0 && index <= 49) {
						return {
							backgroundColor: '#55CF04'
						}
					}
					if (index >= 50 && index <= 69) {
						return {
							backgroundColor: '#01D6D6'
						}
					}
					if (index >= 70 && index <= 100) {
						return {
							backgroundColor: '#FF0036'
						}
					}
				}
				if (type == 'stress') {
					if (index >= 0 && index <= 49) {
						return {
							backgroundColor: '#55CF04'
						}
					}
					if (index >= 50 && index <= 69) {
						return {
							backgroundColor: '#01D6D6'
						}
					}
					if (index >= 70 && index <= 100) {
						return {
							backgroundColor: '#FF0036'
						}
					}
				}
			},
			overAll() {
				overall(this.company, this.branch_content, this.branch_level).then(res => {
					if (res.status == 400) {
						return this.$message.error('暂无数据')
					}
					if (res.status != 200) {
						return this.$message.error('获取失败')
					}
					this.allNum = res.data.allNum;
					this.allEmotion = res.data.emotion_mean;
					this.allStress = res.data.stress_mean;
					this.allTired = res.data.tired_mean;
					this.allEmotionPre = res.data.pre_emotion_mean;
					this.allStressPre = res.data.pre_stress_mean;
					this.allTiredPre = res.data.pre_tired_mean;
					//对比昨天
					if (this.allEmotion >= this.allEmotionPre) {
						let dif = (this.allEmotion / this.allEmotionPre - 1) * 100
						this.contrastEmotion = dif.toFixed(1)
					}
					if (this.allEmotion < this.allEmotionPre) {
						let dif = (1 - this.allEmotion / this.allEmotionPre) * 100
						this.contrastEmotion = dif.toFixed(1)
					}
					if (this.allStress >= this.allStressPre) {
						let dif = (this.allStress / this.allStressPre - 1) * 100
						this.contrastStress = dif.toFixed(1)
					}
					if (this.allStress < this.allStressPre) {
						let dif = (1 - this.allStress / this.allStressPre) * 100
						this.contrastStress = dif.toFixed(1)
					}
					if (this.allTired >= this.allTiredPre) {
						let dif = (this.allTired / this.allTiredPre - 1) * 100
						this.contrastTired = dif.toFixed(1)
					}
					if (this.allTired < this.allTiredPre) {
						let dif = (1 - this.allTired / this.allTiredPre) * 100
						this.contrastTired = dif.toFixed(1)
					}
					//指数颜色和图标
					if (this.allEmotion >= 0 && this.allEmotion <= 49) {
						this.emotionColor = {
							color: '#FF0036'
						}
						this.emotionIcn = 1
					}
					if (this.allEmotion >= 50 && this.allEmotion <= 69) {
						this.emotionColor = {
							color: '#01D6D6'
						}
						this.emotionIcn = 2
					}
					if (this.allEmotion >= 70 && this.allEmotion <= 100) {
						this.emotionColor = {
							color: '#55CF04'
						}
						this.emotionIcn = 3
					}
					if (this.allTired >= 0 && this.allTired <= 49) {
						this.tiredColor = {
							color: '#55CF04'
						}
						this.tiredIcn = 1
					}
					if (this.allTired >= 50 && this.allTired <= 69) {
						this.tiredColor = {
							color: '#01D6D6'
						}
						this.tiredIcn = 2
					}
					if (this.allTired >= 70 && this.allTired <= 100) {
						this.tiredColor = {
							color: '#FF0036'
						}
						this.tiredIcn = 3
					}
					if (this.allStress >= 0 && this.allStress <= 49) {
						this.stressColor = {
							color: '#55CF04'
						}
						this.stressIcn = 1
					}
					if (this.allStress >= 50 && this.allStress <= 69) {
						this.stressColor = {
							color: '#01D6D6'
						}
						this.stressIcn = 2
					}
					if (this.allStress >= 70 && this.allStress <= 100) {
						this.stressColor = {
							color: '#FF0036'
						}
						this.stressIcn = 3
					}
				})
			},
			checkPersonnel() {
				this.$router.push('/personnel')
				sessionStorage.setItem("activePath", '/personnel');
			},
			hourIndex() {
				hour_index(this.company, this.branch_content, this.branch_level).then(res => {
					if (res.status == 400) {
						return this.$message.error('暂无数据')
					}
					if (res.status != 200) {
						return this.$message.error('获取概览失败')
					}
					this.hourAll = res.data.hourIndex
				})
			},
			formatDepartment(row) {
				let department = ''
				if (row.branch_1) {
					department = row.branch_1
				}
				if (row.branch_2) {
					department = department + '|' + row.branch_2
				}
				if (row.branch_3) {
					department = department + '|' + row.branch_3
				}
				if (row.branch_4) {
					department = department + '|' + row.branch_4
				}
				return department
			},
			tableScroll(table, speed = 30) {
				// 拿到表格中承载数据的div元素
				const divData = table.bodyWrapper;
				let timer;
				const startScroll = () => {
					// 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
					clearInterval(timer);
					timer = setInterval(() => {
						// 元素自增距离顶部1像素
						divData.scrollTop += 1;
						// 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
						if (divData.clientHeight + divData.scrollTop + 1 > divData.scrollHeight) {
							// 重置table距离顶部距离
							divData.scrollTop = 0;
						}
					}, speed);
				};
				startScroll();
				//鼠标移入
				divData.onmouseover = () => {
					clearInterval(timer);
				};
				//鼠标移出
				divData.onmouseout = () => {
					clearInterval(timer);
					startScroll();
				};
				//窗口发生改变
				window.onresize = () => {
					return (() => {
						window.screenHeight = document.body.clientHeight;
						document.clientHeight = window.screenHeight;
					})();
				};
			},
			allHourMean() {
				all_hour_mean(this.company, this.branch_content, this.branch_level).then(res => {
					if (res.status != 200) {
						return this.$message.error('获取日心情指数失败')
					}
					this.emotionAllHour = res.data.emotionAllHour;
					this.stressAllHour = res.data.stressAllHour;
					this.tiredAllHour = res.data.tiredAllHour;
					this.emotionChart();
					this.tiredChart();
					this.stressChart();
				})
			},
			emotionChart() {
				let chartDom = document.getElementById('emotion-chart')
				if (this.emotion_chart != '') {
					this.emotion_chart.dispose()
				}
				this.emotion_chart = this.$echarts.init(chartDom);
				let emotionAllHour = this.emotionAllHour;
				let option = {
					tooltip: {
						trigger: 'axis',
						formatter: (params) => {
							let str = '时间:' + params[0].dataIndex + '时<br/>' + params[0].seriesName + ':' + params[
								0].data;
							return str
						}
					},
					grid: {
						left: '5%',
						right: '5%',
						top: '5%',
						bottom: '5%',
					},
					xAxis: {
						type: 'category',
						data: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15',
							'16', '17', '18', '19', '20', '21', '22', '23'
						]
					},
					yAxis: {
						type: 'value',
						max: 100
					},
					series: [{
						name: '心情指数',
						data: emotionAllHour,
						type: 'line',
						symbol: 'circle',
						symbolSize: 10,
						itemStyle: {
							normal: {
								// 动态修改颜色
								color: function(params) {
									if (params.data >= 0 && params.data <= 49) {
										return '#FF0036'
									}
									if (params.data >= 50 && params.data <= 69) {
										return '#01D6D6'
									}
									if (params.data >= 70 && params.data <= 100) {
										return '#55CF04'
									}
								},
								lineStyle: {
									color: '#ccc'
								}
							}
						},
					}]
				};
				this.emotion_chart.clear()
				this.emotion_chart.setOption(option);
			},
			tiredChart() {
				let chartDom = document.getElementById('tired-chart')
				if (this.tired_chart != '') {
					this.tired_chart.dispose()
				}
				this.tired_chart = this.$echarts.init(chartDom);
				let tiredAllHour = this.tiredAllHour;
				let option = {
					tooltip: {
						trigger: 'axis',
						formatter: (params) => {
							let str = '时间:' + params[0].dataIndex + '时<br/>' + params[0].seriesName + ':' + params[
								0].data;
							return str
						}
					},
					grid: {
						left: '5%',
						right: '5%',
						top: '5%',
						bottom: '5%',
					},
					xAxis: {
						type: 'category',
						data: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15',
							'16', '17', '18', '19', '20', '21', '22', '23'
						]
					},
					yAxis: {
						type: 'value',
						max: 100
					},
					series: [{
						name: '疲劳指数',
						data: tiredAllHour,
						type: 'line',
						symbol: 'circle',
						symbolSize: 10,
						itemStyle: {
							normal: {
								// 动态修改颜色
								color: function(params) {
									if (params.data >= 0 && params.data <= 49) {
										return '#55CF04'
									}
									if (params.data >= 50 && params.data <= 69) {
										return '#01D6D6'
									}
									if (params.data >= 70 && params.data <= 100) {
										return '#FF0036'
									}
								},
								lineStyle: {
									color: '#ccc'
								}
							}
						},
					}]
				};
				this.tired_chart.clear()
				this.tired_chart.setOption(option);
			},
			stressChart() {
				let chartDom = document.getElementById('stress-chart')
				if (this.stress_chart != '') {
					this.stress_chart.dispose()
				}
				this.stress_chart = this.$echarts.init(chartDom);
				let stressAllHour = this.stressAllHour;
				let option = {
					tooltip: {
						trigger: 'axis',
						formatter: (params) => {
							let str = '时间:' + params[0].dataIndex + '时<br/>' + params[0].seriesName + ':' + params[
								0].data;
							return str
						}
					},
					grid: {
						left: '5%',
						right: '5%',
						top: '5%',
						bottom: '5%',
					},
					xAxis: {
						type: 'category',
						data: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15',
							'16', '17', '18', '19', '20', '21', '22', '23'
						]
					},
					yAxis: {
						type: 'value',
						max: 100
					},
					series: [{
						name: '压力指数',
						data: stressAllHour,
						type: 'line',
						symbol: 'circle',
						symbolSize: 10,
						itemStyle: {
							normal: {
								// 动态修改颜色
								color: function(params) {
									if (params.data >= 0 && params.data <= 49) {
										return '#55CF04'
									}
									if (params.data >= 50 && params.data <= 69) {
										return '#01D6D6'
									}
									if (params.data >= 70 && params.data <= 100) {
										return '#FF0036'
									}
								},
								lineStyle: {
									color: '#ccc'
								}
							}
						},
					}]
				};
				this.stress_chart.clear()
				this.stress_chart.setOption(option);
			},
			hourWarning() {
				hour_warning(this.company, this.branch_content, this.branch_level).then(res => {
					if (res.status != 200) {
						return this.$message.error('获取预警失败')
					}
					this.emotionWarning = res.data.emotionWarningSort;
					this.stressWarning = res.data.stressWarningSort;
					this.tiredWarning = res.data.tiredWarningSort;
					this.showNotify(this.emotionWarning, '心情指数')
					this.showNotify(this.tiredWarning, '疲劳指数')
					this.showNotify(this.stressWarning, '压力指数')
				})
			},
			showNotify(d, s) {
				let emonum = '';
				for (let i in d) {
					if (s == '心情指数') {
						emonum = d[i].emotion_mean
					}
					if (s == '疲劳指数') {
						emonum = d[i].tired_mean
					}
					if (s == '压力指数') {
						emonum = d[i].stress_meam
					}
					this.$notify({
						title: '情绪状态报警',
						type: 'warning',
						message: `【${d[i].nickname|| '?'}】当前${s}${ emonum|| ''},状态较差，请及时关注！`,
						position: 'bottom-right',
						duration: 0
					});
				}
			
			}
		},
		created() {
			this.adminName = sessionStorage.getItem('name');
			this.branch_content = sessionStorage.getItem('branch-content');
			this.company = sessionStorage.getItem('company');
			this.branch_level = sessionStorage.getItem('level');
			let date = new Date();
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();
			let week = date.getDay();
			this.today = `${year}年${month}月${day}日`
			switch (week) {
				case 0: {
					this.week = '星期日';
					break;
				}
				case 1: {
					this.week = '星期一';
					break;
				}
				case 2: {
					this.week = '星期二';
					break;
				}
				case 3: {
					this.week = '星期三';
					break;
				}
				case 4: {
					this.week = '星期四';
					break;
				}
				case 5: {
					this.week = '星期五';
					break;
				}
				case 6: {
					this.week = '星期六';
					break;
				}
			}
			this.overAll()
			this.hourIndex()
			this.indicatorsAll()
			this.allHourMean()
			this.hourWarning()
			// this.timer=setInterval(()=>{
			//     this.overAll()
			//     this.hourIndex()
			//     this.indicatorsAll()
			//     this.allHourMean()
			//     this.hourWarning()
			// },10000)
		},
		mounted() {
			let emotion = this.$refs.emotion;
			let tired = this.$refs.tired;
			let stress = this.$refs.stress;
			this.tableScroll(emotion)
			this.tableScroll(tired)
			this.tableScroll(stress)
		},
		beforeDestroy() {
			clearInterval(this.timer)
		}
	};
</script>
<style lang='less' scoped>
	.welcome-date {
		display: flex;
		justify-content: flex-start;
	}

	.welcome {
		height: 40px;
		line-height: 40px;
		font-size: 24px;
		color: #333;
		font-weight: bolder;
	}

	.today {
		margin-left: 20px;
		height: 40px;
		line-height: 40px;
		font-size: 16px;
		color: #555;
	}

	.administrator {
		height: 20px;
		line-height: 20px;
		font-size: 14px;
		color: #999;
		font-weight: bolder;
		text-align: left;
	}

	.overall {
		margin-top: 10px;
		display: flex;
		justify-content: space-between;
	}

	.all-item {
		width: 24.5%;
		background-color: #fff;
		border-radius: 5px;
		text-align: left;
	}

	.item-box {
		padding: 30px;
	}

	.all-title {
		height: 20px;
		line-height: 20px;
		font-size: 14px;
		font-weight: bolder;
		color: #333;
	}

	.all-people {
		height: 80px;
		line-height: 80px;
		font-size: 14px;
		color: #333;
	}

	.all-people span {
		font-size: 32px;
		font-weight: bolder;
	}

	.go-check {
		height: 20px;
		line-height: 20px;
		font-size: 14px;
		font-weight: bolder;
		color: #333;
	}

	.index-box {
		height: 80px;
		display: flex;
		justify-content: space-between;
	}

	.mean-index {
		height: 80px;
		line-height: 80px;
		font-size: 14px;
		color: #333;
	}

	.mean-index span {
		font-size: 32px;
		font-weight: bolder;
		margin-left: 10px;
	}

	.index-pic {
		margin-top: 10px;
		height: 60px;
	}

	.contrast {
		height: 20px;
		line-height: 20px;
		font-size: 14px;
		font-weight: bolder;
		color: #333;
	}

	.contrast span {
		margin-right: 10px;
	}

	.hour-index {
		margin-top: 10px;
		display: flex;
		justify-content: space-between;
	}

	.hour-item {
		width: 33%;
		background-color: #fff;
		border-radius: 5px;
	}

	.hour-box {
		padding: 30px;
	}

	.hour-title {
		height: 30px;
		line-height: 30px;
		font-size: 18px;
		font-weight: bolder;
	}

	.index-show {
		display: flex;
		justify-content: center;
		height: 20px;
		vertical-align: middle
	}

	.row-index {
		height: 20px;
		line-height: 20px;
		margin-right: 10px;
	}

	.dot {
		margin-top: 5px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
	}

	.emotion-main {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
	}

	.index-main {
		width: 66.5%;
		background-color: #fff;
		border-radius: 5px;
	}

	.main-box {
		padding: 30px;
	}

	.main-head {
		height: 40px;
		display: flex;
		justify-content: space-between;
	}

	.main-title {
		height: 40px;
		line-height: 40px;
		font-size: 18px;
		font-weight: bolder;
		color: #333;
	}

	.main-title span {
		font-size: 14px;
		color: #ccc;
		margin-left: 10px;
	}

	.warning-main {
		width: 33%;
		background-color: #fff;
		border-radius: 5px;
	}

	.chart-main {
		height: 400px;
		width: 100%;
	}

	#emotion-chart {
		height: 100%;
		width: 100%;
	}

	#tired-chart {
		height: 100%;
		width: 100%;
	}

	#stress-chart {
		height: 100%;
		width: 100%;
	}
</style>
